import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import underthehood from '../assets/images/underthehood.png'
import { SingleStack, CardStacks } from '../components/CardStacks'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import back2future from '../assets/images/back2future.gif'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import '../assets/scss/main.scss'
import '../assets/scss/secondPanel.scss'

import aws from '../assets/layer_pics/aws.png'
import apigateway from '../assets/layer_pics/aws-apigateway.png'
import iam from '../assets/layer_pics/aws-iam.svg'
import lambda from '../assets/layer_pics/aws-lambda.svg'
import azure from '../assets/layer_pics/azure.png'
import docker from '../assets/layer_pics/docker.png'
import dynamo from '../assets/layer_pics/dynamo.png'
import ec2 from '../assets/layer_pics/ec2.png'
import k8 from '../assets/layer_pics/k8.png'
import mongo from '../assets/layer_pics/mongo.png'
import node from '../assets/layer_pics/node.png'
import node2 from '../assets/layer_pics/node2.png'
// import node3 from '../assets/layer_pics/node3.png'
import python from '../assets/layer_pics/python.png'
import ruby from '../assets/layer_pics/ruby.png'
import sql from '../assets/layer_pics/sql.png'
import cloudfront from '../assets/layer_pics/cloudfront.svg'
import arrow5 from '../assets/images/handArrow5.svg'
import SEO from './SEO'
import NavBar from '../components/NavBar'

const BlogPage = () => {
  return (
    <Layout compressed={true}>
      <SEO />
      <NavBar />
      <section
        id="one"
        className="main style1"
        style={{ paddingBottom: '400px' }}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            {/* <h2 style={{ color: '#5a9480' }}>coming soon</h2> */}
            <h2 className={'handwritingalt'} style={{ color: '#5a9480' }}>
              We're working incredibly hard to make Brev, and we can't wait to
              talk all about it here. Stay tuned!
            </h2>
            <p className={'handwritingalt'} style={{ color: '#5a9480' }}>
              -Alec and Nader
            </p>
          </Grid>
        </Grid>
      </section>
    </Layout>
  )
}

export default BlogPage
